<template>
    <div class="myShoppingCart">
        <!-- <headComp></headComp>
        <breadComp></breadComp> -->

        <div class="">
            <div class="container">
                <div class="top">
                    <div class="left">购物车（全部{{totalNum}}）</div>
                    <div class="right">
                        <div class="totalPrice">
                            已选商品（不含运费）：
                            <span>￥{{clearPrice}}</span>
                        </div>
                        <el-button class="clear" type="danger" @click="submit">结算</el-button>
                    </div>
                </div>
                <div class="tableHead cell">
                    <div class="choose">
                        <el-checkbox v-model="selected" @change="changeAll"></el-checkbox>
                        全选
                    </div>
                    <div class="goods">商品</div>
                    <div class="price">单价</div>
                    <div class="num">数量</div>
                    <div class="amount">金额</div>
                    <div class="ope">操作</div>
                </div>
                <div class="tableBody">
                    <div class="item" v-for="(item,i) in cartList" :key="i">
                        <div class="storeLine">
                            <div class="choose">
                                <el-checkbox v-model="item.selected" @change="()=>{storeChange(item)}"></el-checkbox>
                            </div>
                            <div class="store">
                                <img src="../../assets/imgs/effect12.png"/>
                                {{item.storeName}}
                            </div>
                        </div>
                        <div class="goodsLine cell" v-for="(subItem,j) in item.storeGoods" :key="j">
                            <div class="choose">
                                <el-checkbox v-model="subItem.selected" @change="()=>{singleChange(subItem,item)}"></el-checkbox>
                            </div>
                            <div class="goods">
                                <!-- <img src="../assets/imgs/place38.png"/> -->
                                <img :src="subItem.cover"/>
                                <div class="name">{{subItem.name}}</div>
                                <div class="info">
                                    <div class="">规格：{{subItem.specification}}</div>
                                    <div class="">颜色：{{subItem.color}}</div>
                                    <div class="">材质：{{subItem.productMaterial}}</div>
                                </div>
                            </div>
                            <div class="price">￥{{subItem.ourShopPrice}}</div>
                            <div class="num">
                                <el-input-number v-model="subItem.number" @change="handleChange" :min="1" :max="100" label="描述文字"></el-input-number>
                            </div>
                            <div class="amount">￥{{subItem.number*parseFloat(subItem.ourShopPrice)}}</div>
                            <div class="ope">
                                <el-button type="text" @click="delSingleGoods(item,subItem,i,j)">删除</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-show="cartList.length==0">
                        <img src="../../assets/imgs/carEmpty.png" />
                        <div>购物车空空如也~</div>
                    </div>
                </div>
                <div class="tableFoot">
                    <div class="left">
                        <div class="choose">
                            <el-checkbox v-model="selected" @change="changeAll"></el-checkbox>
                            全选
                        </div>
                        <div class="delGoods" @click="delGoodsBatch">删除选中商品</div>
                    </div>
                    <div class="right">
                        <div class="chosenNum">
                            已选 
                            <span>{{clearNum}}</span>
                            件商品
                        </div>
                        <div class="totalPrice">
                            总价（不含运费）：<span>￥{{clearPrice}}</span>
                        </div>
                        <el-button class="clear" type="danger" @click="submit">结算</el-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <footerComp></footerComp> -->
        <payComp class="personStyle" :clearGoodsList="clearGoodsList" @close="closePayComp" v-show="showPayComp"></payComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import payComp from "@/components/payComp.vue"
import {getCartList,deleteCartBatch,deleteCart,submitOrder,getReceiveAddressList} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp,payComp
    },
    data(){
        return{
            num:1,
            selected:false,
            totalNum:0,
            cartList:[],
            clearPrice:0,//结算价格
            clearNum:0,//结算数量
            showPayComp:false,
            receiveAddressList:[],
            clearGoodsList:[],
        }
    },
    methods: {
        handleChange(){},
        // 获取购物车列表
        gainCartList(){
            getCartList().then(res=>{
                // console.log("购物车列表",res);
                if(res.code==200){
                    if(!res.data){
                        this.cartList=[];
                        this.totalNum=0;
                        return
                    }
                    var arr=[]
                    for(var key in res.data.stringListMap){
                        res.data.stringListMap[key].forEach(subItem=>{
                            subItem.selected=false;
                            subItem.number=subItem.number!=''?Number(subItem.number):0
                        });
                        arr.push({
                            storeName:key,
                            storeGoods:res.data.stringListMap[key],
                            selected:false,
                        })
                    }
                    // console.log(arr)
                    this.cartList=arr;
                    this.totalNum=res.data.number;
                }
            })
        },
        // 店铺勾选状态改变
        storeChange(item){
            item.storeGoods.forEach(subItem=>subItem.selected=item.selected)
            this.calcPrice()
        },
        // 单品勾选状态改变
        singleChange(subItem,item){
            if(subItem.selected){
                var t=item.storeGoods.filter(it=>it.selected!=subItem.selected);
                if(t.length==0){
                    item.selected=subItem.selected
                }else{
                    item.selected=false;
                }
            }else{
                item.selected=subItem.selected
            }
            this.calcPrice()
        },
        // 全选
        changeAll(){
            this.cartList.forEach(item=>{
                item.selected=this.selected;
                item.storeGoods.forEach(subItem=>{
                    subItem.selected=this.selected;
                })
            })
            this.calcPrice()
        },
        // 计算价格
        calcPrice(){
            var price=0;
            var num=0;
            this.cartList.forEach(item=>{
                item.storeGoods.forEach(subItem=>{
                    if(subItem.selected){
                        price+=parseFloat(subItem.ourShopPrice)*subItem.number;
                        num+=subItem.number;
                    }
                })
            })
            this.clearPrice=price;
            this.clearNum=num;
        },
        // 批量删除购物车
        delGoodsBatch(){
            var ids=[]
            this.cartList.forEach(item=>{
                item.storeGoods.forEach(subItem=>{
                    if(subItem.selected){
                        ids.push(subItem.shoppingCartId)
                    }
                })
            })
            if(ids.length==0){
                return
            }

            deleteCartBatch(ids).then(res=>{
                // console.log("删除结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message);

                    this.gainCartList();
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 删除单个商品
        delSingleGoods(item,subItem,i,j){
            // // console.log(subItem)
            deleteCart({shoppingCartId:subItem.shoppingCartId}).then(res=>{
                // console.log("删除单个结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message);

                    item.storeGoods.splice(j,1);
                    
                    if(item.storeGoods.length==0){
                        this.cartList.splice(i,1)
                    }
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 提交订单
        submit(){
            var ids=[],goodsArr=[];
            this.cartList.forEach(item=>{
                item.storeGoods.forEach(subItem=>{
                    if(subItem.selected){
                        subItem.calcTotalPrice=subItem.number*parseFloat(subItem.ourShopPrice);
                        ids.push(subItem.shoppingCartId);
                        goodsArr.push(subItem)
                    }
                })
            })
            if(ids.length==0){
                this.$message("未选择商品");
                return
            }

            sessionStorage.carChosenGoodsList=JSON.stringify(goodsArr);
            // console.log(goodsArr)
            // return
            sessionStorage.removeItem('wishChosenGoodsList')
            this.$router.push({
                path:"/wishMatch_clear",
                query:{from:1}
            })

            // var defaultAddressId=this.receiveAddressList.filter(item=>item.isDefault=='Y')[0].receiveAddressId;
            
            // submitOrder({
            //     receiveAddressId:defaultAddressId,
            //     expectedArrivalDate:"2024-06-28",
            //     deliveryType:3,
            //     remark:"",
            //     shoppingCartIdList:ids
            // }).then(res=>{
            //     // console.log("提交结果",res);
            //     if(res.code==200){
            //         this.$message.success(res.msg||res.message)
            //         this.clearGoodsList=goodsArr;
            //         this.showPayComp=true;

            //         this.gainCartList()
            //     }else{
            //         this.$message(res.msg||res.message)
            //     }
            // })
        },
        // 获取收货地址
        gainReceiveAddress(){
            getReceiveAddressList().then(res=>{
                // console.log("收货地址",res);
                if(res.code==200){
                    this.receiveAddressList=res.data
                }
            })
        },
        // 关闭支付弹框
        closePayComp(){
            this.showPayComp=false
        }
    },
    created() {
        this.gainCartList();
        this.gainReceiveAddress();
    },
}
</script>

<style lang="less" scoped>
.myShoppingCart{
    position: relative;
    .container{
        // margin-top: 100px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(229, 229, 229, 1);
        .top{
            padding: 10px 39px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
                font-size: 18px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
            }
            .right{
                display: flex;
                align-items: center;
                .totalPrice{
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    &>span{
                        font-size: 18px;
                        font-weight: 700;
                        color: rgba(212, 48, 48, 1);
                    }
                }
                .clear{
                    margin-left: 76px;
                    height: 50px;
                    width: 109px;
                    border-radius: 25px;
                    font-size: 21px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);
                    background: rgba(200, 64, 57, 1);
                }
            }
        }
        .cell{
            display: flex;
            align-items: center;
            padding: 0 39px;
            .choose{
                width: 60px;
                /deep/.el-checkbox__inner{
                    // background-color: rgba(212, 48, 48, 1);
                    border-color: rgba(212, 48, 48, 1);
                }
                /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                    background-color: rgba(212, 48, 48, 1);
                }
            }
            .goods{
                width: 60%
            }
            .price{
                width: 15%
            }
            .num{
                width: 15%
            }
            .amount{
                width: 5%
            }
            .ope{
                width: 5%
            }
        }
        .tableHead{
            height: 50px;
            background: rgba(245, 245, 245, 1);
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(102, 102, 102, 1);
        }
        .tableBody{
            .item{
                .storeLine{
                    padding: 22px 39px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(230, 230, 230, 1);
                    .choose{
                        /deep/.el-checkbox__inner{
                            // background-color: rgba(212, 48, 48, 1);
                            border-color: rgba(212, 48, 48, 1);
                        }
                        /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                            background-color: rgba(212, 48, 48, 1);
                        }
                    }
                    .store{
                        margin-left: 14px;
                        display: flex;
                        align-items: center;
                        &>img{
                            margin-right: 5px;
                        }
                    }
                }
                .goodsLine{
                    padding: 22px 39px;
                    background: rgba(254, 248, 243, 1);
                    align-items: flex-start;
                    border-bottom: 1px solid rgba(230, 230, 230, 1);
                    &:last-child{
                        border-bottom: none;
                    }
                    .choose{
                        width: 16px;
                    }
                    .goods{
                        margin-left: 14px;
                        display: flex;
                        align-items: flex-start;
                        img{
                            width: 115px;
                            height: 87px;
                        }
                        .name{
                            width: 240px;
                            margin-left: 17px;
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 20.27px;
                            color: rgba(0, 0, 0, 1);
                        }
                        .info{
                            margin-left: 50px;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0px;
                            line-height: 20.27px;
                            color: rgba(128, 128, 128, 1);
                        }
                    }
                    .price{
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(128, 128, 128, 1);
                    }
                    .num{
                        .el-input-number{
                            width: 70px;
                            height: 24px;
                            line-height: 24px;
                            // border: none;
                            /deep/span{
                                width: 20px;
                                background: #fff;
                                // border: none;
                                height: 24px;
                                line-height: 24px;
                                top: 0;
                                &.el-input-number__decrease{
                                    // left: 0;
                                }
                                &.el-input-number__increase{
                                    // right: 0;
                                }
                            }
                            /deep/.el-input{
                                // width: 70px;
                                height: 24px;
                                .el-input__inner{
                                    border: none;
                                    padding-left: 20px;
                                    padding-right: 20px;
                                    // background: rgba(204, 204, 204, 0.2);
                                    height: 24px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                    .amount{
                        font-size: 14px;
                        font-weight: 700;
                        color: rgba(56, 56, 56, 1);
                    }
                    .ope{
                        display: flex;
                        align-items: flex-start;
                        .el-button{
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 4px;
                            margin-bottom: 0;
                            border: none;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(56, 56, 56, 1);
                        }
                    }
                }
            }
            .empty{
                padding: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgba(254, 248, 243, 1);
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                color: rgba(102, 102, 102, 1);
                &>img{
                    width: 300px;
                }
            }
            
        }
        .tableFoot{
            padding: 10px 39px;
            background: rgba(245, 245, 245, 1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            .left{
                display: flex;
                align-items: center;
                .choose{
                    width: 60px;
                    /deep/.el-checkbox__inner{
                        // background-color: rgba(212, 48, 48, 1);
                        border-color: rgba(212, 48, 48, 1);
                    }
                    /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                        background-color: rgba(212, 48, 48, 1);
                    }
                }
                .delGoods{
                    cursor: pointer;
                }
            }
            .right{
                display: flex;
                align-items: center;
                .chosenNum{
                    &>span{
                        font-weight: 700;
                        color: rgba(212, 48, 48, 1);
                        margin-right: 0 2px;
                    }
                }
                .totalPrice{
                    margin-left: 27px;
                    &>span{
                        font-size: 18px;
                        font-weight: 700;
                        color: rgba(212, 48, 48, 1);
                    }
                }
                .clear{
                    margin-left: 38px;
                    height: 50px;
                    width: 109px;
                    border-radius: 25px;
                    font-size: 21px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);
                    background: rgba(200, 64, 57, 1);
                }
            }
        }
    }
    .personStyle{
        position: fixed;
        // top: 269px;
        top: 247px;
        left: calc(50% + 735px);
        background: #fff;
        border: 1px solid #ccc;
        border: none;
        margin-left: 30px;
    }
}
</style>